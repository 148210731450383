import React from 'react';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Card = (props: CardProps) => {
  return (
    <div
      className={`bg-white shadow-md p-4 sm:p-8 rounded-lg ${
        props.className ?? ''
      }`}
    >
      {props.children}
    </div>
  );
};

export default Card;
